import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { useTranslation } from 'react-i18next';
import PersonalData from "../PersonalData"

function About() {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-md-3">
        <img src={PersonalData().image_about} alt={PersonalData().name} />
      </div>
      <div className="col-md-9">
        <h2 className="mt-4 mt-md-0 mb-4">{ t('about.title') }</h2>
        <p className="mb-0">{ t('about.subtitle') }</p>
        <div className="row my-4">
          <div className="col-md-6">
            <p className="mb-2">
              { t('about.name') } <span className="text-dark">{PersonalData().name}</span>
            </p>
            <p className="mb-0">
              { t('about.birthday') } <span className="text-dark">{ t('personal_data.birthday') }</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-2">
              { t('about.location') } <span className="text-dark">{ PersonalData().location }</span>
            </p>
            <p className="mb-0">
              { t('about.email') } <span className="text-dark">{ PersonalData().email }</span>
            </p>
          </div>
        </div>
        <a href={PersonalData().cvpath_br} className="btn btn-default mt-1 mr-2" target="_blank" rel="noopener noreferrer">
          <i className="icon-cloud-download"></i>{ t('about.buttons.download_cv_pt') }
        </a>

        <a href={PersonalData().cvpath_en} className="btn btn-default mt-1 mr-2" target="_blank" rel="noopener noreferrer">
          <i className="icon-cloud-download"></i>{ t('about.buttons.download_cv_en') }
        </a>
        <ScrollLink
          activeClass="active"
          to="section-contact"
          spy={true}
          smooth={true}
          duration={500}
          offset={50}
          className="btn btn-alt mt-1 mt-md-0 mt-xs-1"
        >
          <i className="icon-envelope"></i>{ t('about.buttons.contact_me') }
        </ScrollLink>
      </div>
    </div>
  );
}

export default About;
