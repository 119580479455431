import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Remova a importação do Backend e LanguageDetector
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

// Importar os arquivos de tradução diretamente
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json'; // Se houver tradução em português

const fallbackLng = ['en'];
// eslint-disable-next-line
const availableLanguages = ['en', 'pt'];

// Configurar os recursos de tradução
const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init({
    resources,
    fallbackLng, // fallback language is english.
    lng: 'en', // Define o idioma padrão

    interpolation: {
      escapeValue: false, // react já faz o escape por padrão
    },

    debug: false, // Defina como true se precisar de informações de depuração
  });

export default i18n;
