import React, { useState, useEffect } from "react";
import { useTranslation} from 'react-i18next';

const filters = [
  {
    id: 1,
    name: "en",
  },
  {
    id: 2,
    name: "pt-BR",
  }
];

function Language() {
  const [activeFilter, setActiveFilter] = useState("");
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  useEffect(() => {
    setActiveFilter(filters[0].name.toLowerCase());
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    let targetFilter = e.target.value
      ? e.target.value.toLowerCase()
      : e.target.textContent.toLowerCase();
    setActiveFilter(targetFilter);
    changeLanguage(targetFilter);
    console.log(targetFilter);
  }

  return (
    <>
      <ul className="portfolio-filter list-inline">
        {filters.map((filter) => (
          <li
            className={
              filter.name.toLowerCase() === activeFilter
                ? "list-inline-item bold current"
                : "list-inline-item bold"
            }
            key={filter.id}
            onClick={(e) => handleChange(e)}
          >
            {filter.name.toUpperCase()}
          </li>
        ))}
      </ul>

      <div className="pf-filter-wrapper mb-4">
        <select
          className="portfolio-filter-mobile"
          onChange={(e) => handleChange(e)}
        >
          {filters.map((filter) => (
            <option value={filter.name} key={filter.id}>
              {filter.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export default Language;
