import React from "react";
import Experience from "../Items/Experience";
import PersonalData from "../PersonalData"

function Experiences() {
  return (
    <div className="timeline">
      {PersonalData().experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
