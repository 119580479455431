import React, {useEffect} from "react";
import { Link } from "react-router-dom";

function Whatsapp() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace('https://wa.me/5585981341809');
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <section
        className="demosection herosection mt-0 background parallax shadow-dark d-flex align-items-center padding"
        style={{ backgroundImage: "url(/images/hero.jpg)" }}
      >
        <div className="container">
          <div className="demo-content">
            <h1>Redirecting! </h1>
            <h4>Wait 3 seconds</h4>
          </div>
        </div>
        <div className="overlay"></div>
      </section>
      <div className="demoitems">
        <div className="container">
          <h2>Welcome! You will be redirected to marllon's whatsapp in 3 seconds...</h2><br/>
          <Link to="/" className="btn btn-default">
              Go to home
          </Link>
        </div>
      </div>
    </>
  );
}

export default Whatsapp;

