import React from "react";
import TrackVisibility from "react-on-screen";
import Funfact from "../Items/Funfact";
import { useTranslation } from 'react-i18next';


function Funfacts() {
  const { t } = useTranslation();
  const funfactData = [
    {
      id: 1,
      title: t('fun_fact.lines'),
      count: 1989743,
      icon: "icon-note",
    },
    {
      id: 2,
      title: t('fun_fact.coffee'),
      count: 27,
      icon: "icon-cup",
    },
    {
      id: 3,
      title: t('fun_fact.customers'),
      count: 1610211,
      icon: "icon-emotsmile",
    },
    {
      id: 4,
      title: t('fun_fact.compiled'),
      count: 987437,
      icon: "icon-energy",
    },
  ];
  return (
    <section className="shadow-dark color-white background parallax padding-50">
      <div className="row relative z-1 -mt-50">
        {funfactData.map((funfact) => (
          <div className="col-md-3 col-sm-6 mt-50" key={funfact.id}>
            <TrackVisibility once>
              <Funfact funfact={funfact} />
            </TrackVisibility>
          </div>
        ))}
      </div>
      <div className="overlay"></div>
    </section>
  );
}

export default Funfacts;
