import React, {useEffect} from "react";
import { Link } from "react-router-dom";

function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section
        className="demosection herosection mt-0 background parallax shadow-dark d-flex align-items-center padding"
        style={{ backgroundImage: "url(/images/hero.jpg)" }}
      >
        <div className="container">
          <div className="demo-content">
            <h1>Ops! 😞</h1>
            <h4>Page not found</h4>
          </div>
        </div>
        <div className="overlay"></div>
      </section>
      <div className="demoitems">
        <div className="container">
          <h2>The page you are looking for doesn't exist or another error occurred.</h2><br/>
          <Link to="/" className="btn btn-default">
              try go to home
          </Link>
        </div>
      </div>
    </>
  );
}

export default NotFound;

