import { Link } from "react-router-dom";
import Language from "../Sections/Language";
import { useTranslation } from 'react-i18next';
import PersonalData from "../PersonalData"
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaGithub,
  FaMailBulk,
} from "react-icons/fa";

function Header({ toggleHeader, toggleHandler }) {
  const { t } = useTranslation();

  const headerData = {
    name: PersonalData().name,
    designation: t('header.designation'),
    imageThumb: "/images/logo.png",
    social: {
      facebook: PersonalData().social.facebook,
      linkedin: PersonalData().social.linkedin,
      instagram: PersonalData().social.instagram,
      github: PersonalData().social.github,
      email: PersonalData().social.email,
    },
  };

  return (
    <>
      <div
        className={
          toggleHeader
            ? "mobile-header py-2 px-3 mt-4 push"
            : "mobile-header py-2 px-3 mt-4"
        }
      >
              
        <button className="menu-icon mr-2" onClick={toggleHandler}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Link to="/" className="logo">
          <img src={headerData.imageThumb} alt={headerData.name} />
        </Link>
        <Link to="/" className="site-title dot ml-2">
          {headerData.name}
        </Link>
      </div>

      <header
        className={
          toggleHeader
            ? "left float-left shadow-dark open"
            : "left float-left shadow-dark"
        }
      >
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggleHandler}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="header-inner d-flex align-items-start flex-column">
          <Link to="/">
            <img src={headerData.imageThumb} alt={headerData.name} />
          </Link>
          <Link to="/" className="site-title dot mt-3">
            {headerData.name}
          </Link>

          <span className="site-slogan">{headerData.designation}</span>

          <nav>
            <ul className="vertical-menu scrollspy">
              <li>
                <Link to="/multipage">
                  <i className="icon-home"></i>{t('header.navigation.items.home')}
                </Link>
              </li>
              <li>
                <Link to="/about">
                  <i className="icon-user"></i>{t('header.navigation.items.about')}
                </Link>
              </li>
              <li>
                <Link to="/services">
                  <i className="icon-bulb"></i>{t('header.navigation.items.services')}
                </Link>
              </li>
              <li>
                <Link to="/resume">
                  <i className="icon-graduation"></i>{t('header.navigation.items.resume')}
                </Link>
              </li>
              <li>
                <Link to="/works">
                  <i className="icon-grid"></i>{t('header.navigation.items.works')}
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <i className="icon-phone"></i>{t('header.navigation.items.contact')}
                </Link>
              </li>
              <li>
                <Link to="/">
                  <i className="icon-close"></i>{t('header.navigation.items.exit')}
                </Link>
              </li>
            </ul>
          </nav>

          <Language/>

          <div className="footer mt-auto">
            <ul className="social-icons list-inline">
              {!headerData.social.facebook ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.facebook} target="_blank" rel="noopener noreferrer">
                    <FaFacebookF />
                  </a>
                </li>
              )}
              {!headerData.social.linkedin ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.linkedin} target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                  </a>
                </li>
              )}
              {!headerData.social.instagram ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.instagram} target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </li>
              )}
              {!headerData.social.github ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.github} target="_blank" rel="noopener noreferrer">
                    <FaGithub />
                  </a>
                </li>
              )}
              {!headerData.social.email ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.email} target="_blank" rel="noopener noreferrer">
                    <FaMailBulk />
                  </a>
                </li>
              )}
            </ul>

            <span className="copyright">
              &copy; {new Date().getFullYear()} {t('header.navigation.copyright')}
            </span>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;

