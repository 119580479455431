import { useTranslation } from 'react-i18next';
import { SiKotlin, SiJava, SiSwift, SiFlutter, SiCodecademy, SiFirebase } from "react-icons/si";
import translations from '../locales/en/translation.json';

function PersonalData() {
  const { t } = useTranslation();
  const experiencesSize = Object.keys(translations.experiences).length-1;
  const generateExperienceData = (size, t) => {
    let experiencesData = [];
    for (let id = size; id >= 1; id--) {
      experiencesData.push({
        id: id,
        year: t(`experiences.xp_${id}.year`),
        degree: t(`experiences.xp_${id}.degree`),
        content: t(`experiences.xp_${id}.content`)
      });
    }
    return experiencesData;
  };
  const personalData = { 
    name: "Marllon Sóstenes",
    cvpath_br: "media/cv_marllons_pt_0423.pdf",
    cvpath_en: "media/cv_marllons_en_0423.pdf",  
    ranking: "media/ranking-de-onboarding-2022.pdf",
    imageThumb: "/images/logo.png",
    image_about: "images/about.png",
    location: "Fortaleza, Ceará, Brasil",
    email: "marllonsostenes@gmail.com",
    social: {
      facebook: "https://facebook.com/marllonsostenes",
      linkedin: "https://linkedin.com/in/m4rllons/",
      instagram: "https://www.instagram.com/m4rllons",
      github: "https://www.github.com/marllons",
      email: "mailto:marllonsostenes@gmail.com?subject=subject to Marllon&body=hi Marllon!",
    },
    progressData: [
      {
        id: 1,
        name: "Java",
        percentage: 95,
      },
      {
        id: 2,
        name: "Kotlin",
        percentage: 97,
      },
      {
        id: 3,
        name: "Swift",
        percentage: 90,
      },
      {
        id: 4,
        name: "Flutter",
        percentage: 70,
      },
      {
        id: 5,
        name: "Delphi (Object Pascal)",
        percentage: 80,
      },
      {
        id: 6,
        name: "JavaScript (React, Nest, Next, Node, etc)",
        percentage: 75,
      },
    ],
    servicesData : [
      {
        id: 1,
        name: t('services.kotlin.title'),
        content: t('services.kotlin.description'),
        icon: <SiKotlin/>,
      },
      {
        id: 2,
        name: t('services.java.title'),
        content: t('services.java.description'),
        icon: <SiJava/>,
      },
      {
        id: 3,
        name: t('services.flutter.title'),
        content: t('services.flutter.description'),
        icon: <SiFlutter/>,
      },
      {
        id: 4,
        name: t('services.swift.title'),
        content: t('services.swift.description'),
        icon: <SiSwift/>,
      },
      {
        id: 5,
        name: t('services.web_development.title'),
        content: t('services.web_development.description'),
        icon: <SiCodecademy/>,
      },
      {
        id: 6,
        name: t('services.firebase.title'),
        content: t('services.firebase.description'),
        icon: <SiFirebase/>,
      },
    ],
    experiencesData : generateExperienceData(experiencesSize, t),
    filters: [
      {
        id: 1,
        name: t('portfolio.filters.all'),
      },
      {
        id: 2,
        name: t('portfolio.filters.android'),
      },
      {
        id: 3,
        name: t('portfolio.filters.web'),
      },
      {
        id: 4,
        name: t('portfolio.filters.ios'),
      },
      {
        id: 5,
        name: t('portfolio.filters.delphi'),
      },
    ],
    allData: Object.values(translations.portfolio.items)
  };

  return personalData;
}

  export default PersonalData