import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "../Items/Skill";
import { useTranslation } from 'react-i18next';
import PersonalData from "../PersonalData"

function Skills() {
  const { t } = useTranslation();
  return (
    <>
      <p className="mb-0">{t('skills.subtitle')}</p>
      <div className="mt-5">
        <div className="row -mt-50">
          {PersonalData().progressData.map((progress) => (
            <div className="col-md-6 mt-50" key={progress.id}>
              <TrackVisibility once>
                <Skill progress={progress} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
